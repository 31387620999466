import React, {useState, useEffect} from "react";
import {useToasts} from "react-toast-notifications";
import {UnitListingDescriptionService} from "@common/units-api";
import {authManager} from "@common/authentication";
import {ListingDescriptionAppRender} from "./ListingDescriptionAppRender";
import {ParseHelper} from "../helpers/parse-helper";
import _ from "lodash";

let unitId: number;
let userEmail: string;

export const ListingDescriptionApp: React.FunctionComponent<any> = (props) => {
    const [hasPermissions, setHasPermissions] = useState(false);
    const [loadingApp, setLoadingApp] = useState(false);
    const [loading, setLoading] = useState(false);
    const [unitListingDescription, setUnitListingDescription] = useState('');
    const { addToast } = useToasts();

    const token = authManager.getJwt();
    const unitListingDescriptionService = new UnitListingDescriptionService(token);

    /** Load and set data */
    useEffect(() => {
        (async () => {
            try {
                setLoadingApp(true);
                await loadData();
            } catch (e) {
                console.error("error unit listing description app", e);
            } finally {
                setLoadingApp(false);
            }
        })();
    }, []);

    /** Resize iframe for each render */
    useEffect(() => {
        resizeContent();
    });

    const loadData = async () => {
        if (_.isNil(unitId)) {
            const info = authManager.getInfoFromAdmin<{unitId: number; userEmail: string; hasPermissions: boolean}>();
            unitId = info.unitId;
            userEmail = info.userEmail;
            setHasPermissions(info.hasPermissions);
        }
    };

    const generateListingDescription = async () => {
        setLoading(true);
        try {
            const listingDescription = await fetchUnitListingDescription(unitId);
            setUnitListingDescription(listingDescription);
        } catch (error) {
            console.log(error);
            addToast(`Error generating Unit Listing Description. Please try again later.`, { appearance: "error" });
        }
        setLoading(false);
    };

    const fetchUnitListingDescription = async (unitId: number): Promise<string> => {
        const listingDescriptionData = await unitListingDescriptionService.getGeneratedListingDescription(unitId);
        return ParseHelper.fromUnitListingDescriptionDataToString(listingDescriptionData);
    };

    const resizeContent = () => {
        const height = document.documentElement.offsetHeight;
        window.parent.postMessage({action: `${height}px`, type: "resize"}, "*");
    };

    return (
        <ListingDescriptionAppRender
            unitListingDescription={unitListingDescription}
            generateListingDescription={generateListingDescription}
            loadingApp={loadingApp}
            loading={loading}
            hasPermissions={hasPermissions}
        />
    );
};
